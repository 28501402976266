.row {
    padding-top: 30px;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .row {
        padding: 20px;
    }

    .avatar {
        align-items: center;
    }
}