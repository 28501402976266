.body {
    padding-top: 32px;
    min-height: 150px;
    height: 100%;
    overflow: hidden;
    /* background-color: #f0f2f5; */
}

.breadcrumb {
    margin: 20px 0;
}

.rowHeader {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    text-align: left;
    width: calc(60% - 16px);
}

.rowHeaderLeft {
    width: 50%;
}

.rowHeaderRight {
    width: 50%;
    margin-left: 20px
}

.rowBody {
    display: flex;
    text-align: center;
    flex-direction: row;
}

.containerIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.iconDevice {
    margin-right: 7px;
    align-items: center;
    /* background-color:rgb(73, 149, 196); */
}

.columnMap {
    width: 100%;
    padding: 5px;
    background-color: white;
}

.columnContent {
    padding: 0px;
    width: 0%;
    margin-left: 30px;
}

.row {
    display: flex;
    margin-top: 5px;
    font-size: 13px;
    align-items: center;
}

.columnLeft {
    text-align: right;
    padding-right: 20px;
    min-width: 40%;
}

.columnRight {
    text-align: left;
    min-width: 60%;
}

.buttonSettingContainer {
    position: absolute;
    top: 85px;
    right: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
}

.settingPopup {
    position: absolute;
    top: 85px;
    right: 50px;
    width: 300px;
    height: 470px;
    line-height: 20px;
    text-align: center;
    border-radius: 5px;
    padding: 20px ;
    background-color: white;
    border: 1px solid #DDDDDD;
}

.fadeOut{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 1000ms, opacity 1000ms;
}
.fadeIn{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 1000ms;
}

@media (max-width: 767px) {
    .body {
        padding-top: 20px;
    }
    
    .container {
        margin-top: 20px !important;
    }

    .rowHeader {
        flex-direction: column;
        margin-top: 0px;
        width: 100%;
    }

    .rowHeaderLeft {
        width: 100%;
        margin-bottom: 10px;
    }

    .rowHeaderRight {
        width: 100%;
        margin: 0px;
    }

    .breadcrumb {
        margin: 16px 0;
    }

    .columnMap {
        width: 100%;
        padding: 5px;
        margin: 0px;
    }
    .columnContent {
        text-align: left;
        width: 100%;
        margin-left: 0px;
        padding-top: 20px;
        padding-left: 0px;
    }
    .row {
        font-size: 12px;
    }
    .site-layout-background {
        padding: 5px;
    }

    .buttonSettingContainer {
        top: 73px;
    }

    .settingPopup {
        top: 121px;
        right: 14px;
        width: 93%;
        margin: 0 auto;
    }

    .modal {
        width: 90%;
    }

    .row {
        font-size: 12px;
    }
}