.list tr {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  padding: 20px;
  align-items: center;
}

.list {
  margin-top: 2rem;
  width: 100%;
  border-collapse: collapse;
}

.infoLabel {
  display: block;
  opacity: 0.9;
  font-size: 13px;
  text-align: right;
}

.body {
  padding: 3rem 2rem;
}

@media (max-width: 767px) {
  .infoLabel {
    font-size: 11px;
    text-align: left;
  }

  .list tr {
    padding: 20px 0;
  }

  .body {
    padding: 2rem 1rem;
  }
}
