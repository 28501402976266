.banner .name {
  color: white;
}

.banner .logo {
  display: flex;
  align-items: center;
}

.banner .logo img {
  height: 40px;
}

.banner .logo .name {
  margin-left: 10px;
}

.panel {
  display: flex !important;
  height: 100%;
  border-radius: 10px;
  justify-content: center;
}

.panel .content {
  margin: auto auto;
  width: 100%;
  background-color: white;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* @media (max-width: 767px) {
  .panel .content {
    margin: 20px;
  }
} */

/* .uploadCertificateButton {
  height: 40px;
  padding: 9.5px 15px;
  font-size: 16px !important;
  border-radius: 2px;
  color: white;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  line-height: 1.5715;
  cursor: pointer;
  margin-right: 1rem;
  font-weight: 400;
} */
